
.nav-elements {
    display: none;
}
.menu-icon {
    display: none;
    cursor: pointer;
  }


  /*  */
/* Base Styles for Mobile */
.ul-flex {
    display: flex;
    flex-direction: column;
  }
  
  .ul-space-y-4 {
    gap: 1rem;
  }
  
  .ul-p-4 {
    padding: 1rem;
  }
  
  .ul-text-gray-800 {
    color: #333;
  }
  
  .ul-hover\:ul-text-blue-600:hover {
    color: #007bff;
  }
  
  .ul-cursor-pointer {
    cursor: pointer;
  }
  
  .ul-w-full {
    width: 100%;
  }
  
  .text-left {
    text-align: left;
  }
  
  .ul-hidden {
    display: none;
  }
  
  .ul-absolute {
    position: absolute;
  }
  
  .ul-left-0 {
    left: 0;
  }
  
  .ul-bg-white {
    background-color: white;
  }
  
  .ul-shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .ul-z-10 {
    z-index: 10;
  }
  
  .ul-rounded-md {
    border-radius: 0.375rem;
  }
  
  .ul-top-full {
    top: 100%;
  }
  
  .ul-space-y-2 {
    gap: 0.5rem;
  }
  
  .ul-block {
    display: block;
  }
  
  .ul-py-2 {
    padding: 0.5rem 0;
  }
  
  .ul-px-4 {
    padding: 0 1rem;
  }
  
  .ul-hover\:ul-bg-blue-100:hover {
    background-color: #f0f9ff;
  }
  
  /* Show the dropdown when activated */
  .ul-show {
    display: block;
  }
  
  /*  */

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: fixed;
    right: -50px;
    top: 0;
    background-color: #fef7e5;
    width: 0px;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 1000;
  }

  .nav-elements.active {
    width: 100vw;
    right:0;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

