.error-msg {
  background-color: rgba(255, 109, 109, 0.498);
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.success-msg {
  background-color: rgba(109, 255, 138, 0.498);
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.alert {
  background-color: rgb(255, 116, 116);
}

.notification-toast {
  height: auto;
  position: fixed;
  width: fit-content;
  top: 13vh;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  background-color: rgb(147, 215, 147);
  z-index: 1000;
  transition: ease-in-out 2s;
}

/* Hovernav buttons drop downs */
.whoweare:hover .whoweare-dropdown {
  display: block;
  box-shadow: rgb(206, 214, 221) 3px 0px 10px 0px inset,
    rgba(255, 255, 255, 0.596) -3px 0px 10px 0px inset;
  background: rgb(255, 255, 255);
}
.partnerships:hover .partnerships-dropdown {
  display: block;
  box-shadow: rgb(206, 214, 221) 3px 0px 10px 0px inset,
    rgba(255, 255, 255, 0.596) -3px 0px 10px 0px inset;
  background: rgb(255, 255, 255);
}
.achievement:hover .achievement-dropdown {
  display: block;
  box-shadow: rgb(206, 214, 221) 3px 0px 10px 0px inset,
    rgba(255, 255, 255, 0.596) -3px 0px 10px 0px inset;
  background: rgb(255, 255, 255);
}
.programs:hover .programs-dropdown {
  display: block;
  box-shadow: rgb(206, 214, 221) 3px 0px 10px 0px inset,
    rgba(255, 255, 255, 0.596) -3px 0px 10px 0px inset;
  background: rgb(255, 255, 255);
}

.nav-drodn a {
  position: relative; /* Needed for the ::after element */
}

.nav-drodn:hover a::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 1s ease-in-out;
}

.dropdown-items {
  margin-top: 25px;
}

/* CARDS START #####################*/
.program-card {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.image-card-icon {
  position: relative; /* Add this */
  text-align: center;
  background-color: #fff;
  width: 100%;
}

.image-card-icon-icon {
  background: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30px;
  position: relative;
}

.image-card-icon-hover {
  position: absolute;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 15%; */
  opacity: 0;
}

.image-card-icon-img {
  background-size: cover !important;
  background-position: center;
  width: 100%;
  height: 240px;
}
.trans {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.image-card-icon-holder {
  width: 120px;
  left: calc(50% - 60px);
  position: relative;
  top: -60px;
  height: 60px;
  border-radius: 100px 100px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-card-icon-holder img {
  width: 50px;
  height: 50px;
}

.image-card-icon-title {
  height: 70px;
}

image-card-icon-hover {position:absolute;width:100%;height:100%;z-index: 8;display: flex;justify-content: center;align-items: center;padding: 0 15%; opacity:0;}
.image-card-icon:hover .image-card-icon-hover {opacity:1} 
.image-card-icon-hover p.h5-title {color:#fff; font-size:26px; font-family:var(--h5-font-family); }
.image-card-icon-hover p {color:#fff; }


@media (max-width: 768px) {
.image-card-icon:hover .image-card-icon-hover {opacity:0} 

}

/* CARDS END #######################*/

/* BUTTONS - START #######################*/
.tw-btn-primary {
  border-radius: 100px;
  color: white;
  border-color: gray;
  background: theme("colors.prim-purple");
  padding: 11px 25px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  width: max-content;
  transition: ease-in-out 0.2s;
}
.tw-btn-primary:hover {
  background: theme("colors.prim-dark");
}

/* BUTTONS - END #######################*/

/*  HOME page SVG */
.img-page-banner-svg-a {
  top: -200px;
}
/* .img-page-banner-svg-a {
  animation: rotateAnimation 3s linear 1;
  animation-fill-mode: none;
  animation-fill-mode: forwards;
}
.img-page-banner-svg-a {
  position: absolute;
  top: -100px;
  left: -100px;
  transform: rotate(90deg);
  opacity: 0.6;
} */

.img-page-banner-svg-a {
  position: absolute;       /* Needed for z-index to work */
  top: -100px;
  left: -100px;
  transform: rotate(90deg);
  opacity: 0.6;
  z-index: 0 ;             /* Apply z-index */
  animation: rotateAnimation 3s linear 1;
  animation-fill-mode: forwards; /* Use one animation-fill-mode value */
}


.img-page-banner-svg-b {
  position: absolute;
  top: 200px;
  right: 0;
  transform: rotate(0deg);
  overflow: hidden;
  width: 360px;
}

.inner_svg {
  position: absolute;
  left: calc(278px - 50%);
  top: calc(316px - 50%);
  opacity: 0.5;
  height: 260px;
  width: 260px;
}
.outer_svg {
  animation: rotateAnimationReverse 3s linear 1;
  height: 449px;
  width: 449px;
  opacity: 0.8;
}
/*  */

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg); /* Rotate by 50% (180 degrees) */
  }
}

/* Apply the animation to your SVG element */
.img-page-banner-svg-a {
  animation: rotateAnimation 3s linear 1;
  animation-fill-mode: forwards;
}
.img-page-banner-svg-b {
  position: absolute;
  top: 200px;
  right: 0;
  transform: rotate(0deg);
  overflow: hidden;
  width: 360px;
}

.inner_svg {
  animation: rotateAnimation 3s linear 1;
}
.inner_svg {
  position: absolute;
  left: calc(278px - 50%);
  top: calc(316px - 50%);
  opacity: 0.5;
  height: 260px;
  width: 260px;
}
.outer_svg {
  animation: rotateAnimationReverse 3s linear 1; /* Adjust the duration and timing function as needed */
  height: 449px;
  width: 449px;
  opacity: 0.8;
}

@keyframes rotateAnimationReverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg); /* Rotate by 50% (180 degrees) */
  }
}
