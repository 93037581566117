/* custom-accordion.css */
.accordion-button {
    background-color: #f9f9f9; /* Default color */
    color: #000; /* Default text color */
}

.accordion-button:not(.collapsed) {
    background-color: theme("colors.prim-purple"); /* Color when selected */
    color: white; /* Text color when selected */
}

.accordion-button:focus {
    box-shadow: none; /* Remove the outline */
}

/* Slider *//* Slider animations with different speeds */
.tw-animate-scroll-up-slow {
  animation: scroll-up 25s linear infinite; /* Slow speed */
}

.tw-animate-scroll-up-medium {
  animation: scroll-up 20s linear infinite; /* Medium speed */
}

.tw-animate-scroll-up-fast {
  animation: scroll-up 15s linear infinite; /* Fast speed */
}

.tw-animate-scroll-down-slow {
  animation: scroll-down 35s linear infinite; /* Slow speed */
}

.tw-animate-scroll-down-medium {
  animation: scroll-down 30s linear infinite; /* Medium speed */
}

.tw-animate-scroll-down-fast {
  animation: scroll-down 25s linear infinite; /* Fast speed */
}

/* Keyframes for scrolling animations */
@keyframes scroll-up {
  0% { transform: translateY(0); }
  100% { transform: translateY(-50%); }
}

@keyframes scroll-down {
  0% { transform: translateY(-50%); }
  100% { transform: translateY(0); }
}
